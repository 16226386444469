import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import stepbg from '../../images/slicing/our story/stepbg.png'
import workHours from '../../images/slicing/our story/workHours.png'
import workLocation from '../../images/slicing/our story/workLocation.png'
import workDays from '../../images/slicing/our story/workDays.png'
import mindset from '../../images/slicing/our story/mindset.png'
const serviceValueStyle = {
    backgroundImage: `url(${stepbg})`,
    backgroundSize: 'contain',
    backgroundOrigin: 'content-box',
    backgroundPosition: 'inherit',
    backgroundRepeat: 'no-repeat',
    paddingRight: '0px'
}
const HowWeWorkComponent = () => {
    return (
        <Container fluid style={serviceValueStyle} className="mt-5 how-we-work">
            <Container >

                <Row>
                    <Col className="carousel-columns">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="line"></div>
                            <div>
                                <h6 className="carousel-section-subheading">WORK CULTURE</h6>
                            </div>
                        </div>
                        <h2 className="text-uppercase">How We Work </h2>
                    </Col>
                </Row>
                <Row className="d-none d-md-flex d-lg-flex">
                    <Col className="mt-4">
                        <img src={workHours} alt="Not Found" />
                        <h5 className="font-weight-bold" style={{ fontSize: '24px' }}>Work Hours</h5>
                        <p>
                            We are not the usual 9-5.
                        </p>
                    </Col>
                    <Col style={{ marginTop: '80px' }}>
                        <img src={workLocation} alt="Not Found" />
                        <h5 className="font-weight-bold" style={{ fontSize: '24px' }}>Work Location</h5>
                        <p>
                            We are not the traditional Office Desk.
                        </p>
                    </Col>
                    <Col className="mt-4">
                        <img src={workDays} alt="Not Found" />
                        <h5 className="font-weight-bold" style={{ fontSize: '24px' }}>Work Days</h5>
                        <p>
                            We are not the typical 6 Days a Week office routine.
                        </p>
                    </Col>
                    <Col style={{ marginTop: '80px' }}>
                        <img src={mindset} alt="Not Found" />
                        <h5 className="font-weight-bold" style={{ fontSize: '24px' }}>Mindset</h5>
                        <p>
                            We are focused and dedicated towards our work...
                        </p>
                    </Col>
                </Row>
                <Row className="d-md-none d-lg-none d-xl-none" noGutters>
                    <Col sm="4" xs="4">
                        <img width="81px" src={workHours} alt="Not Found" />
                    </Col>
                    <Col sm="8" xs="8">

                        <h5 className="font-weight-bold" style={{ fontSize: '18px' }}>Work Hours</h5>
                        <p>
                            We are not the usual 9-5.
                        </p>
                    </Col>

                </Row>
                <Row className="d-flex d-md-none d-lg-none d-xl-none ">
                    <Col sm="4" xs="4"> <img width="81px" src={workLocation} alt="Not Found" /></Col>
                    <Col sm="8" xs="8">

                        <h5 className="font-weight-bold" style={{ fontSize: '18px' }}>Work Location</h5>
                        <p>
                            We are not the traditional Office Desk.
                        </p>
                    </Col>

                </Row>
                <Row className="d-md-none d-lg-none d-xl-none">
                    <Col sm="4" xs="4"> <img width="81px" src={workDays} alt="Not Found" /></Col>
                    <Col sm="8" xs="8">

                        <h5 className="font-weight-bold" style={{ fontSize: '18px' }}>Work Days</h5>
                        <p>
                            We are not the typical 6 Days a Week office routine.
                        </p>
                    </Col>

                </Row>
                <Row className="d-md-none d-lg-none d-xl-none">
                    <Col sm="4" xs="4"> <img width="81px" src={mindset} alt="Not Found" /></Col>
                    <Col sm="8" xs="8">

                        <h5 className="font-weight-bold" style={{ fontSize: '18px' }}>Mindset</h5>
                        <p>
                            We are focused and dedicated towards our work...
                        </p>
                    </Col>

                </Row>
            </Container>
        </Container>
    );
};

export default HowWeWorkComponent;