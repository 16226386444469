import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/our story/banner.png'
import AchievementsSections from '../components/homepage/AchievementsSections'
import HowWeWorkComponent from '../components/aboutus/HowWeWorkComponent'
import { Row, Col, Container } from 'react-bootstrap'
import { strategyOurStory } from '../staticdata/homepageServicesData'
import WorkStrategy from '../components/services/WorkStrategy'
class AboutUsContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Our Story"
                    bannerImage={banner}
                    text="Our foundations were built on Ethics, Excellence and Passion for Technology...Each one of us keeps it alive within us, every day!" />
                <Container>
                    <Row>
                        <Col>
                            <h2 className="font-weight-bold pt-5 text-center">What Makes Our Work Culture Different. It's</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className=" text-center text-uppercase text-standard">GTEN <b>values</b></h3>
                        </Col>
                    </Row>
                </Container>
                <WorkStrategy data={strategyOurStory} />
                <Container>
                    <Row className="py-5 px-3">
                        <Col>
                            <p className="initial-paragrapgh">
                            The GTEN family has always been guided by a strong sense of belonging and togetherness, fueled by acommon passion to help our clients achieve their goals through technological superiority. Our culture hasbeen of complete transparency and mutual growth of both, individuals and the company as a whole.
                        </p>
                        </Col>
                    </Row>
                </Container>
                <hr />
                <AchievementsSections />
                <hr />
                <Container className="py-4">
                    <Row>
                        <Col>
                            <h2 className="font-weight-bold text-left text-uppercase text-standard">Different in Skills, Equal in Stature...</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text-justify p-3" style={{ lineHeight: 2 }}>
                                As an organization, each one of us might hold a different designation
                                based on our work profile. But at its core, we believe in maintaining
                                equality when it comes to capabilities, respect and opportunities.
                                While maintaining a horizontal hierarchy, the GTEN work culture is
                                about providing the platform for dynamic minds to explore their
                                potential and be an integral part of each project. As a result,
                                each one of us is supported to take our own decisions in the
                                best interest of our clients and feel equal responsibility towards our work.
                        </p>
                        </Col>
                        <Col>
                            <p className="text-justify p-3" style={{ lineHeight: 2 }}>
                                When an organization’s culture is built with keeping the employees’ best
                                interest in mind, it shows in the company’s performance. Our HR Policies
                                are carefully crafted, just like the solutions which we deliver,
                                so that they are resilient enough to provide a well-functioning workplace,
                                but flexible enough to ensure the freedom of dynamism and employee-comfort.
                        </p>
                        </Col>
                    </Row>
                </Container>
                <hr />
                 <HowWeWorkComponent />
                <Container fluid style={{backgroundColor:'#f7f6f9'}}>
                    <Container className="py-4">
                    <Row>
                        <Col>
                            <h2 className="font-weight-bold text-left text-uppercase text-standard">We would love you to be part of this culture!</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text-justify p-3" style={{ lineHeight: 2 }}>
                                As we expand and overcome boundaries, we are constantly looking out
                                for the best ones to join our band wagon. As a symbiotic relationship,
                                if you have the knack for innovative thinking and a zest for technology,
                                we provide you with the platform to showcase your skills.
                        </p>
                        </Col>
                    </Row>
                    </Container>
                    
                </Container>
            </>
        )
    }
}

export default AboutUsContainer